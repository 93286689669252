import "./App.css";
import AccessoItalianoApp from "./main/AccessoItalianoApp";
import BackgroundComponent from "./main/components/BackgroundComponent";
import FooterComponent from "./main/components/core/FooterComponent";
import HeaderComponent from "./main/components/core/HeaderComponent";

function App() {
  return (
    <div className="App">
      <BackgroundComponent />
      <HeaderComponent />
      <div className="app-content">
        <AccessoItalianoApp />
      </div>
      <FooterComponent />
    </div>
  );
}

export default App;
