import "../assets/style/HomeComponent.css";

export default function HomeComponent() {
  return (
    <div className="home-component">
      <div className="menu-container glass-effect text shadow-drop-center ">
        <div className="left-content ">
          <h1 className="flicker-1">Accedi ai servizi</h1>
        </div>

        <div className="right-content">
          <span className="button-space" />
          <button className="neomorphism-button home-button">Nexus</button>
          <span className="button-space" />
          <button className="neomorphism-button home-button">Nextcloud</button>
        </div>
      </div>
    </div>
  );
}
