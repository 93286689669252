import { Routes, Route, Navigate } from "react-router-dom";
import LoginComponent from "./components/core/LoginComponent";
import RecoveryComponent from "./components/RecoveryComponent";
import TermsComponent from "./components/TermsComponent";
import HomeComponent from "./components/HomeComponent";
import ErrorComponent from "./components/ErrorComponent";
import "./assets/style/AccessoItaliano.css";
import PrivacyPolicyComponent from "./components/PrivacyPolicyComponent";
import CookieComponent from "./components/CookieComponent";
import NexusViewComponent from "./components/web/NexusViewComponent";

export default function AccessoItalianoApp() {
  const isAuthenticated = localStorage.getItem("authToken"); // Logica di autenticazione

  return (
    <div className="accesso-italiano-app">
      <Routes>
        <Route path="/" element={<LoginComponent />} />
        <Route path="/recovery" element={<RecoveryComponent />} />
        <Route
          path="/home"
          element={
            isAuthenticated ? (
              <HomeComponent />
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />
        <Route path="/error" element={<ErrorComponent />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyComponent />} />
        <Route path="/cookie-policy" element={<CookieComponent />} />
        <Route path="/terms-and-conditions" element={<TermsComponent />} />
        <Route path="/nexus" element={<NexusViewComponent />} />
      </Routes>
    </div>
  );
}
