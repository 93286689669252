/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import "../../assets/style/LoginComponent.css";
import { ReactComponent as LowVisionIcon } from "../../assets/images/low-vision.svg";
import { useNavigate } from "react-router-dom";

export default function LoginComponent() {
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [showSuccesMessage, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const navigate = useNavigate();

  function handleUsernameChange(event) {
    setShowErrorMessage(false);
    setUsername(event.target.value);
  }

  function handlePasswordChange(event) {
    setShowErrorMessage(false);
    setPassword(event.target.value);
  }

  function handleSubmit() {
    if (username === "danilo" && password === "dummy") {
      setShowSuccessMessage(true);
      setShowErrorMessage(false);
      localStorage.setItem("authToken", "test");
      navigate("/home");
    } else {
      setShowSuccessMessage(false);
      setShowErrorMessage(true);
    }
  }

  function togglePasswordVisibility() {
    setPasswordVisible(!passwordVisible);
  }

  function goToRecovery() {
    navigate("/recovery");
  }

  return (
    <div className="login-component">
      <div className="login-container shadow-drop-center text glass-effect">
        {showSuccesMessage && (
          <div className="success-message">Authenticated succesfully</div>
        )}
        {showErrorMessage && (
          <div className="error-message">Credenziali non valide</div>
        )}
        <div className="login-form">
          <div>
            <label className="login-label">Username</label>
            <br />
            <input
              type="text"
              name="username"
              value={username}
              onChange={handleUsernameChange}
            />
          </div>
          <div>
            <label className="login-label">Password</label>
            <br />
            <div className="password-wrapper">
              <input
                type={passwordVisible ? "text" : "password"} // Alterna tra "text" e "password"
                name="password"
                value={password}
                onChange={handlePasswordChange}
                className="password-input"
              />
              <button
                type="button"
                className="toggle-password-btn"
                onClick={togglePasswordVisibility}
              >
                <LowVisionIcon />
              </button>
            </div>
          </div>
          <div className="recovery-text">
            <a className="recovery-link" onClick={goToRecovery}>
              Recupera le credenziali
            </a>
          </div>
          <div>
            <button
              className="login-button neomorphism-button"
              onClick={handleSubmit}
            >
              Accedi
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
