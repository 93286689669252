import React from "react";

export default function NexusComponent() {
  return (
    <div className="nexus-component">
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "block",
          position: "relative",
          zIndex: "30",
        }}
      >
        <iframe
          src="https://93.41.239.164/nexus/"
          title="WebView"
          style={{
            width: "100vw",
            height: "100vh",
            border: "none",
            position: "relative",
          }}
        ></iframe>
      </div>
    </div>
  );
}
