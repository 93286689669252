import { useNavigate } from "react-router-dom";
import "../assets/style/ErrorComponent.css";

export default function ErrorComponent() {
  const navigate = useNavigate();

  function goToHome() {
    navigate("/");
  }
  return (
    <div className="error-component">
      <div className="error-container shadow-drop-center text glass-effect">
        <h2>ERRORE</h2>
        <button class="neomorphism-button" onClick={goToHome}>
          Home
        </button>
      </div>
    </div>
  );
}
