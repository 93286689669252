import "../assets/style/CookieComponent.css";

export default function CookieComponent() {
  return (
    <div className="cookie-component">
      <div className="kie-container text glass-effect">
        <div className="privacy-menu">
          <a className="privacy-link" href="/terms-and-conditions">
            <h3>Terms & Conds</h3>
          </a>
          <h1 className="colored">Cookie Policy</h1>
          <a className="privacy-link" href="/privacy-policy">
            <h3>Privacy Policy</h3>
          </a>
        </div>
        <h3 style={{ textAlign: "center", marginTop: "5%" }}>
          <i>
            Accesso Italiano utilizza solo cookie tecnici indispensabili per il
            funzionamento del sito e dei servizi offerti
          </i>
        </h3>
        <p
          className="
          secondary-text"
        >
          <u className="colored ">Cosa sono i cookie?</u>
          <br />I cookie sono piccoli file di testo salvati sul dispositivo
          dell'utente <br /> <br />
          Tipologie di cookie utilizzati da Accesso Italiano:
          <br />
          <span className="subtitle">- Cookie tecnici:</span> necessari per
          l'accesso ai servizi (es. autenticazione e mantenimento della
          sessione)
          <br />
          <span className="subtitle">- Cookie di performance:</span> utilizzati
          per ottimizzare l'esperienza utente.
        </p>
      </div>
    </div>
  );
}
